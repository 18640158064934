<template>
    <div class="my-box">
        <div class="content-box">
            <van-list
                v-model="loading"
                :finished="finished"
                offset="100"
                finished-text="数据加载完毕"
                @load="onLoad"
            >
                <div class="content-item" v-for="item in list" :key="item.id"   @click="$router.push(`/eventDetail/${item.id}`)">
                    <div class="left">
                        <img :src="item.img" alt />
                    </div>
                    <div class="right">
                        <div>
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="two-block">{{ item.data }}</div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { List } from "vant";
import { selectRecruitTeacherRealEvents } from "@/api/home";
export default {
    components: {
        [List.name]: List,
    },
    data() {
        return {
            bookValue: "",
            bookOption: [],
            list: [],
            loading: false,
            finished: false,
            page: 0,
            limit: 10
        };
    },
    async created() {
        document.title = "真实事件";
        this.$store.commit("updateBarText", "真实事件");
    },
    methods: {
        onLoad() {
            this.page = this.page + 1
            this.getList()
        },
        getOnceList() {
            selectRecruitTeacherRealEvents({
                page: this.page,
                certId: this.bookValue,
                limit: this.limit,
            }).then(res => {
                const { data } = res.data;
                if (data.data.length == 0) {
                    this.finished = true;
                    this.list = data.data
                } else {
                    this.list = data.data
                    if (data.current_page == data.last_page) {
                        this.finished = true;
                    } else {
                        this.loading = true;
                    }
                }
            })
        },
        getList() {
            selectRecruitTeacherRealEvents({
                page: this.page,
                certId: this.bookValue,
                limit: this.limit,
            }).then(res => {
                const { data } = res.data;

                if (data.data.length == 0) {
                    this.finished = true;
                } else {
                    this.list = [...this.list, ...data.data]
                    if (data.current_page == data.last_page) {
                        this.finished = true;
                    } else {
                        this.loading = true;
                    }
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.my-box {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    padding-top: 50px;
    .content-box {
        .content-item {
            height: 100px;
            margin-top: 15px;
            display: flex;
            border-bottom: 1px solid #d6d6d6;
            .left {
                width: 100px;
                height: 80px;
                > img {
                    width: 100px;
                    height: 80px;
                }
            }
            .right {
                div {
                    &:nth-of-type(1) {
                        display: flex;
                        width: 210px;
                        justify-content: space-between;
                        padding-top: 5px;
                        padding-left: 15px;
                        color: #203152;
                        font-size: 16px;
                    }
                    &:nth-of-type(2) {
                        padding-top: 10px;
                        padding-left: 15px;
                        color: #203152;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>